import { FocusMonitor, FocusTrapFactory, InteractivityChecker } from '@angular/cdk/a11y';
import { CdkDialogContainer } from '@angular/cdk/dialog';
import { OverlayRef } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, NgZone, Optional } from '@angular/core';
import { UIKitDefaultDialogConfig } from './dialog-config';

@Component({
  selector: 'uikit-dialog-container',
  styles: [`
    :host {
      display: block;
      background: #fff;
      border-radius: 4px;
      width: 100%;
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.16));
      z-index: 20000;
      overflow: hidden;
    }
  `],
  template: `
  <ng-template cdkPortalOutlet></ng-template>
  `
})
export class UIKitDialogContainer extends CdkDialogContainer<UIKitDefaultDialogConfig> {

  constructor(
    elementRef: ElementRef,
    focusTrapFactory: FocusTrapFactory,
    @Optional() @Inject(DOCUMENT) _document: any,
    dialogConfig: UIKitDefaultDialogConfig,
    interactivityChecker: InteractivityChecker,
    ngZone: NgZone,
    overlayRef: OverlayRef,
    focusMonitor?: FocusMonitor,
  ) {
    super(
      elementRef,
      focusTrapFactory,
      _document,
      dialogConfig,
      interactivityChecker,
      ngZone,
      overlayRef,
      focusMonitor,
    );

  }
}
